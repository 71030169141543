import React, { Component } from 'react'
import Link from '../utils/link'
import { Logo, Linkedin, TikTok } from './icons'

class Header extends Component {

  state = {
    offCanvas: false,
  }

  _hideOffCanvas = () => {
    this.setState({ offCanvas: false })
  }

  _toggleOffCanvas = () => {
    this.setState({ offCanvas: !this.state.offCanvas })
  }

  render() {

    let { offCanvas } = this.state

    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active',
    }

    return (
      <>
        <header className={ `header header--${ this.props.className }` }>
          <div className='header__inner'>
            <nav className='header__nav'>
              <ul>
                <li><Link to='https://www.linkedin.com/company/talent-run-aus' { ...props }><Linkedin color='#FFFFFF'/></Link></li>
                <li><Link to='https://www.tiktok.com/@talent_run' { ...props }><TikTok color='#FFFFFF'/></Link></li>
              </ul>
            </nav>
            <Link to='/' title='Talent Run' className='header__logo' { ...props }>
              <Logo color='#FFFFFF'/>
            </Link>
            <button onClick={ this._toggleOffCanvas } className={ offCanvas ? 'header__hamburger active' : 'header__hamburger' }>
              <span className='lines'></span>
            </button>
          </div>
        </header>
        <div className={ `off-canvas ${ offCanvas && 'off-canvas--active' } off-canvas--${ this.props.className }` }>
          <div className='off-canvas__inner'>
            <nav className='off-canvas__nav'>
              <ul>
                <li><Link to='/about/' { ...props }>About</Link></li>
                <li><Link to='/i-want-to-hire/' { ...props }>I want to Hire</Link></li>
                <li><Link to='/i-want-a-job/' { ...props }>I want a Job</Link></li>
              </ul>
            </nav>
          </div>
        </div>
      </>
    )
  }
}

export default Header
